import { MissionCard } from './mission-card';
import { MissionContainer } from './mission-container';

export const Mission = () => {
  return (
    <MissionContainer>
      <div className="relative z-10 mx-auto max-w-[850px]">
        <h1 className="mb-14 text-4xl font-extrabold leading-[46px] md:text-5xl md:leading-[60px]">
          The Protocol Labs Network drives{' '}
          <span className="text-blue-600">breakthroughs in computing</span> to
          push humanity forward.
        </h1>
      </div>
      <div className="relative z-10 flex flex-col text-center">
        <MissionCard />
      </div>
    </MissionContainer>
  );
};
